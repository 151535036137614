import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from './main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class ContenutiService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "contenuti/";
  }

  getItem(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + id)
  }

  sendItem(id:number, body: any) {
    body.id = id;
    return this.http.post<any>(this.apiUrl , body);
  }
}
