import { Component, OnInit } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from '../service/notification.service';

@Component({
  selector: 'app-info-versione',
  templateUrl: './info-versione.component.html',
  styleUrls: ['./info-versione.component.scss']
})
export class InfoVersioneComponent implements OnInit {

  showUpdateAlert = false;
  newVersion: string;
  actVersion: string;

  constructor(
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService
  ) {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      if (value) {
        this.showUpdateAlert = (value.versionMismatch) ? true : false;
        this.newVersion = value.newVersion;
        this.actVersion = this.mainEngineService.VERSION;
      }
    });
  }

  ngOnInit(): void {
  }

  refresh(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      });
    }
    window.location.reload();
  }

}
