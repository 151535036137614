//https://www.npmjs.com/package/ngx-ui-loader
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { scan, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
  spin :Subject<boolean> = new Subject()

  constructor(
    private ngxLoader: NgxUiLoaderService
  ) {
    this.spin
    .subscribe(
      (res) => {
        if(res){ this.ngxLoader.start() }
        else {
          this.ngxLoader.stop();
        }
      }
    )

/*
    this.spin
    .asObservable()
    .pipe(
      map(val => val ? 1 : -1 ),
      scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
    )
    .subscribe(
      (res) => {
        if(res === 1){ this.ngxLoader.start() }
        else if( res == 0 ){
          this.ngxLoader.stop();
        }
      }
    )
*/
  }

  showSpinner(){
    this.spin.next(true);
  }

  stopSpinner(){
    this.spin.next(false);
  }

  setSpinner(value){
    this.spin.next(value);
  }

}
