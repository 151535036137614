import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampiSeoService {
  arrayCampi: any[any];

  constructor() {
    this.arrayCampi = {
      'social_titolo' : {'id' : 'social_titolo', 'nome': 'Titolo'},
      'social_testo' : {'id' : 'social_testo', 'nome': 'Testo'},
      'social_immagine' : {'id' : 'social_immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': false},
      'seo_title' : {'id' : 'seo_title', 'nome': 'Title'},
      'seo_description' : {'id' : 'seo_description', 'nome': 'Description'},
      'seo_keywords' : {'id' : 'seo_keywords', 'nome': 'Keywords'},
    };
  }
}
