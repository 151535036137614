import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReservedAccessService } from './components/reservedAccess/reserved-access.service';
import { UserLogged } from './components/reservedAccess/user-logged';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  user: UserLogged;

  constructor(
    private router: Router,
    private reservedAccessService: ReservedAccessService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.reservedAccessService.isUserLoggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }
}
