import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputService } from '../formInput/form-input.service';
import { CampiSeoService } from './campi-seo.service';

@Component({
  selector: 'div[app-campi-seo]',
  templateUrl: './campi-seo.component.html',
  styleUrls: ['./campi-seo.component.scss']
})
export class CampiSeoComponent implements OnInit {
  arrayCampi: any[any];
  inputClass: string;
  constructor(
    private campiSeoService: CampiSeoService,
    private formComponentsService: FormInputService
  ) {
    this.arrayCampi = this.campiSeoService.arrayCampi;
    this.inputClass = this.formComponentsService.inputClass;
  }

  @Input() myForm: FormGroup;

  ngOnInit(): void {
  }

}
