import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Categoria } from '../categoria';
import { OpzioniCategoria } from '../opzioni-categoria';


@Component({
  selector: 'app-categorie-scheda',
  templateUrl: './categorie-scheda.component.html',
  styleUrls: ['./categorie-scheda.component.scss']
})
export class CategorieSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  elencoLink: string;
  nomeSezione: string;
  myItem: Categoria;
  sottocategorie = false;
  opzioni: OpzioniCategoria;

  @Input() myService;
  @Input() idCategoria: number;
  @Input() backUrl: string;


  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo'},
    };

  }

  ngOnInit(): void {
    let urlArray = this.componentsService.getActualRouteArray();
    if (this.myService.idCategoria) {
      this.sottocategorie = true;
      this.elencoLink = urlArray[1]+'/'+urlArray[2]+'/'+urlArray[3]+'/'+urlArray[4];
    }
    else {
      this.elencoLink = urlArray[1]+'/'+urlArray[2];
    }

    if (this.sottocategorie) {
      this.arrayCampi.idCategoria = {'id' : 'idCategoria', 'defValue' : this.idCategoria, 'type': 'NUM'}
    }

    this.myService.getOpzioni(this.idCategoria)
    .subscribe(data => {
      this.opzioni = data;

      if (this.opzioni.testo) {
        this.arrayCampi['richText'] = {'id' : 'richText', 'nome': 'Testo'};
      }
      if (this.opzioni.immagine) {
        this.arrayCampi['immagine'] = {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': true};
      }

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);

      this.route.params.subscribe(
        (params) => {
          if (params.id != '0') {
            this.backUrl += '/lastItem/' + params.id;
            this.formComponentsService.activateLoader(true);
            this.myService.getItem(params.id, this.idCategoria)
            .subscribe(data => {
              this.myItem = data;

              this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

              this.myForm.patchValue(this.myItem);
              this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
              this.formComponentsService.activateLoader(false);
            });
          }
          else {
            this.titoloScheda = "Inserimento elemento";
          }
        })
      });
    }


  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.myService.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.backUrl]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.elencoLink + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onCancel() {
    this.componentsService.onCancel(this.backUrl);
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('myItem' in changes) {
      this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);
      this.myForm.patchValue(this.myItem);
    }
  }
}
