import { Routes } from '@angular/router';
import { TestClientComponent } from 'src/app/pages/test-client/test-client/test-client.component';

export const WhiteLayoutRoutes: Routes = [
  {
    path: '',
    component: TestClientComponent
  },
];

