import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { TestStatus } from '../test/test-status';

@Injectable({
  providedIn: 'root'
})
export class TestClientService {
  apiUrl: string;
  linkBase: string = "clientTest";


  constructor(    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  sendCodice(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/sendCodice' , body);
  }

  sendRisposta(codiceUtente: string, codiceTest: string, domanda: number, risposta: number) {
    return this.http.post<any>(this.apiUrl + 'op/sendRisposta' , {codiceUtente, codiceTest, domanda, risposta});
  }

  checkCodice(codiceUtente: string) {
    return this.http.get<any>(this.apiUrl + 'op/checkCodice?codiceUtente=' + codiceUtente);
  }


  exitTest(codiceUtente: string) {
    return this.http.post<any>(this.apiUrl + 'op/exitTest' , {codiceUtente});
  }

  saveUserCode(code: string) {
    localStorage.setItem('code', code);
  }

  getUserCode(): string {
    return localStorage.getItem('code');
  }

  getStatus(codiceUtente: string = ""): Observable<TestStatus> {
    return this.http.get<TestStatus>(this.apiUrl + 'op/getStatus?codiceUtente=' + codiceUtente);
  }

}
