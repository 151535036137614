import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElencoService {



  constructor() { 
  }

  setSortOrder(myService: any, value: any): void {
    myService.$sortOrder.next(value);
  }

  getSortOrder(myService: any): number {
    return myService.$sortOrder.value;
  }

  setCurrentePage(myService: any, value: number): void {
    myService.$currentPage.next(value);
  }

  getCurrentePage(myService: any): number {
    return myService.$currentPage.value;
  }

  setPageSize(myService: any, value: number): void {
    myService.$pageSize.next(value);
  }

  getPageSize(myService: any): number {
    return myService.$pageSize.value;
  }

  setScrollPosition(myService: any, value: number): void {
    myService.$scrollPosition.next(value);
  }

  getScrollPosition(myService: any): number {
    return myService.$scrollPosition.value;
  }

  setSearchString(myService: any, value: string): void {
    myService.$searchString.next(value);
  }

  getSearchString(myService: any): string {
    return myService.$searchString.value;
  }  
}
