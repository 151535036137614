import { Routes } from '@angular/router';
import { InfoVersioneComponent } from 'src/app/components/info-versione/info-versione.component';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { ProfiloComponent } from 'src/app/components/profilo/profilo.component';
import { DomandeElencoComponent } from 'src/app/pages/domande/domande-elenco/domande-elenco.component';
import { DomandeSchedaComponent } from 'src/app/pages/domande/domande-scheda/domande-scheda.component';
import { TestPreHomeComponent } from 'src/app/pages/test/test-pre-home/test-pre-home.component';



export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: TestPreHomeComponent
  },
  {
    path: 'home',
    component: TestPreHomeComponent
  },
  {
    path: 'profilo',
    component: ProfiloComponent
  },
  {
    path: 'domande/lastItem/:id',
    component: DomandeElencoComponent
  },
  {
    path: 'domande',
    component: DomandeElencoComponent
  },
  {
    path: 'domande/:id',
    component: DomandeSchedaComponent
  },
  {
    path: 'test',
    component: TestPreHomeComponent
  },
  {
    path: 'infoVersione',
    component: InfoVersioneComponent
  },
  {
    path: '404',
    component: Page404Component
  },
];

