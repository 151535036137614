import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { OpzioniCategoria } from '../opzioni-categoria';
import { first } from 'rxjs/operators';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categorie-elenco',
  templateUrl: './categorie-elenco.component.html',
  styleUrls: ['./categorie-elenco.component.scss']
})
export class CategorieElencoComponent implements OnInit {
  columns: any[any];
  options: any[any] = [];
  lastId: number = 0;

  @Input() myService;
  @Input() idCategoria: number;
  @Input() backUrl: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
        ];

        if (params.idCategoria == undefined) {
          this.columns.push({ columnDef: 'numSottocategorie', header: 'Sottocategorie', class: 'mat-column-right', style: {'width': '80px', 'flex': 'none'}, cell: (element: any) => `${element.numSottocategorie}` })
        }

        this.columns.push({ columnDef: 'numElementi', header: 'Elementi', class: 'mat-column-right', style: {'width': '80px', 'flex': 'none'}, cell: (element: any) => `${element.numElementi}` })

        this.options = {
          linkBase: this.myService.linkBase
        }

        if (params.idCategoria == undefined) {
          this.options['customButtons'] = {
            sottocategorie: {
              type: 'custom',
              testo: 'Sottocategorie',
              colorClass: 'info',
              icon: 'bx bx-list-ul',
              actionTooltip: 'Gestione sottocategorie',
              routerLink: 'sottocategorie',
              hideText: true
            }
          };
        }
      });
  }
}
