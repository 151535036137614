import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteLayoutComponent } from './white-layout.component';
import { RouterModule } from '@angular/router';
import { WhiteLayoutRoutes } from '../../../custom/layouts/local-white-layout/white-layout.routing';



@NgModule({
  declarations: [
    WhiteLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(WhiteLayoutRoutes),
  ],
  exports: [
    WhiteLayoutComponent
  ]
})
export class WhiteLayoutModule { }
