import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ContenutiService } from 'src/app/service/contenuti.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-socials',
  templateUrl: './socials.component.html',
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: any;
  titoloScheda: string = "Caricamento...";

  THIS_ID: number = 100;

  constructor(
    private formComponentsService: FormInputService,
    private contenutiService: ContenutiService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService
  ) {
    this.arrayCampi = {
      'Facebook' : {'id' : 'Facebook', 'nome': 'Link pagina Facebook', 'type': 'URL'},
      'Twitter' : {'id' : 'Twitter', 'nome': 'Link pagina Twitter', 'type': 'URL'},
      'Instagram' : {'id' : 'Instagram', 'nome': 'Link pagina Instagram', 'type': 'URL'},
      'YouTube' : {'id' : 'YouTube', 'nome': 'Link pagina YouTube', 'type': 'URL'},
      'Linkedin' : {'id' : 'Linkedin', 'nome': 'Link pagina Linkedin', 'type': 'URL'},
      'Pinterest' : {'id' : 'Pinterest', 'nome': 'Link pagina Pinterest', 'type': 'URL'},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.contenutiService.getItem(this.THIS_ID)
    .subscribe(data => {
      this.myItem = data;
      this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

      this.formComponentsService.activateLoader(false);
      this.titoloScheda = "Modifica elemento";
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }



  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.contenutiService.sendItem(this.THIS_ID, this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/']);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel('/');
  }
}
