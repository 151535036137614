/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-password',
  templateUrl: './campo-password.component.html',
  styleUrls: ['./campo-password.component.scss']
})
export class CampoPasswordComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  mostraNote: boolean = true;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }


  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (this.config.fullWidth) {
      this.inputClass = "col-12";
	    this.mostraNote = false;
    }
    if (!this.config.id) {
      this.config.id = 'password';
    }
    if (!this.config.nome) {
      this.config.nome = 'Password';
    }
    if (!this.config.icona) {
      this.config.icona = 'lock_outline';
    }
    if (!this.config.shortInput) {
      this.config.shortInput = false;
    }
    if (!this.config.strenghtMeter) {
      this.config.strenghtMeter = false;
    }
  }
}
