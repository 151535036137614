/******
Versione 1.0.20220616
******/

import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormInputService } from '../form-input.service';
import { startWith } from 'rxjs/operators';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-campo-file',
  templateUrl: './campo-file.component.html',
  styleUrls: ['./campo-file.component.scss']
})
export class CampoFileComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  estensioniProibite: string[] = ['php','php3','php4','php5','phtml','asp','aspx','ascx','jsp','cfm','cfc','pl','bat','exe','dll','reg','cgi', 'sh', 'py','asa','asax','config','com','inc'];
  estensioniAmmesse: string = null;
  actValue: string = "";
  actUrl: string = "";
  arrayPulsanti: any[any];
  fileAggiunto: boolean = false;
  fileEliminato: boolean = false;

  constructor(
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;

      this.arrayPulsanti = {
        'download' : {'type' : 'download', 'btnSize': 'xs', 'MB': 1},
        'elimina' : {'type' : 'delete', 'btnSize': 'xs', 'ML': true, 'MB': 1},
        'ripristina' : {'type' : 'ripristina', 'btnSize': 'xs', 'ML': true, 'MB': 1},
      };


  }

  @Input() myForm: FormGroup;
  @Input() config: any;
  @Output() onAddFile = new EventEmitter<any[any]>();

  get objInput() {
    return this.myForm.get(this.config.id);
  }

  checkFile($event) {
    let pass = true;
    this.objInput.setErrors(null);

    let fileName = $event.target.value;
    let emitValue = null;
    if (fileName != '') {
      let posizione_punto = fileName.lastIndexOf(".");
      let lunghezza_stringa = fileName.length;
      let estensione = fileName.substring(posizione_punto+1,lunghezza_stringa);
      estensione = estensione.toLowerCase();

      this.estensioniProibite.forEach((item) => {
        if (item == estensione) {
          this.objInput.setErrors({'incorrect': true});
          pass = false;
          return;
        }
      });

      if (!pass) {
        return;
      }

      if (this.config.estensioniAmmesse) {
        let allowed = false;
        this.config.estensioniAmmesse.forEach((item) => {
          if (item == estensione) {
            allowed = true;
          }
        });
        if (!allowed) {
          this.objInput.setErrors({'incorrect': true});
          return;
        }
      }
      $event.target.files[0].id = this.config.id;
      emitValue = $event.target.files[0];
      this.fileAggiunto = true;
    }
    else {
      emitValue = {id : this.config.id};
      this.fileAggiunto = false;
    }

    if (this.myForm.get(this.config.id).value != null) {
      if (fileName != '') {
        $("#fileAttuale_" + this.config.id).addClass("fileEliminato");
        this.fileEliminato = true;
      }
      else {
        $("#fileAttuale_" + this.config.id).removeClass("fileEliminato");
        this.fileEliminato = false;
      }
    }
    this.onAddFile.emit(emitValue);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'file';
    }
    if (this.config.nome == undefined) {
      this.config.nome = 'File';
    }
    if (!this.config.readonly) {
      this.config.readonly = false;
    }
    if (!this.config.estensioniAmmesse) {
      this.config.estensioniAmmesse = null;
    }
    else {
      this.estensioniAmmesse = "Tipologie di file ammessi: " + this.config.estensioniAmmesse.join(",");
    }
    this.myForm.addControl(this.config.id+'_file',new FormControl(''));
    this.myForm.addControl(this.config.id+'_nuovo',new FormControl(''));
    this.myForm.addControl(this.config.id+'_nome',new FormControl(''));
    this.myForm.addControl(this.config.id+'_elimina',new FormControl(''));


    this.myForm.get(this.config.id).valueChanges
    .pipe(startWith("")).subscribe(x => {
      if (this.myForm.get(this.config.id).value !== null) {
        this.actValue = this.myForm.get(this.config.id).value.nome;
        this.actUrl = this.myForm.get(this.config.id).value.url;
        this.myForm.get(this.config.id + '_nuovo').clearValidators();
      }
   })

  }

  onClickElimina() {
    if (this.config.required) {
      this.notificationsService.show('warning', '', 'Questo campo è obbligatorio e può essere solamente sostituito con un altro file tramite il pulsante "Scegli file"');
    }
    else {
      const field = this.config.id+'_elimina';
      this.myForm.patchValue({[field] : true});
      this.myForm.markAsDirty();
      $("#fileAttuale_" + this.config.id).addClass("fileEliminato");

      this.fileEliminato = true;
    }
  }


  onClickRipristina() {
    const field = this.config.id+'_elimina';

    this.myForm.patchValue({[field] : false});
    $("#fileAttuale_" + this.config.id).removeClass("fileEliminato");

    this.fileEliminato = false;
  }
}
