import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ComponentsService } from '../../component.service';
import { FormInputService } from '../../scheda/formInput/form-input.service';
import { User } from '../user';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-users-dettaglio',
  templateUrl: './users-dettaglio.component.html',
  styleUrls: ['./users-dettaglio.component.scss']
})
export class UsersDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: User;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private userService: UsersService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'nome' : {'id' : 'nome', 'nome': 'Cognome e nome', 'required': true},
      'email' : {'id' : 'email', 'nome': 'Email', 'note': 'Questo campo vale anche come username', 'required': true},
    };
  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.linkBase = this.userService.linkBase;
    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.userService.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.formComponentsService.activateLoader(false);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.userService.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.userService.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }
}
