import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProfiloService } from './profilo.service';
import { UserLogged } from '../reservedAccess/user-logged';
import { ReservedAccessService } from '../reservedAccess/reserved-access.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  arrayPulsanti: any[any];
  myFormEmail: FormGroup;
  myFormPassword: FormGroup;
  loading = false;
  arrayCampiEmail: any[any];
  arrayCampiPassword: any[any];
  myItem: any;
  titoloScheda: string = "Caricamento...";
  user: UserLogged;
  pannelloModifica: string = '';

  THIS_ID: number = 100;

  constructor(
    private formComponentsService: FormInputService,
    private service: ProfiloService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService,
    private reservedAccessService: ReservedAccessService
  ) {
    this.arrayCampiEmail = {'email' : {'id' : 'email', 'nome': 'Email', 'type': 'EML', 'required': true}};
    this.arrayCampiPassword = {'passwordAttuale' : {'id' : 'passwordAttuale', 'nome': 'Password attuale', 'shortInput': true, 'type': 'PWD', 'required': true }
                            // tslint:disable-next-line: max-line-length
                            , 'passwordNuova' : {'id' : 'passwordNuova', 'nome': 'Nuova password', 'shortInput': true, 'strenghtMeter': true, 'type': 'PWD', 'required': true  }
                            , 'passwordRipeti' : {'id' : 'passwordRipeti', 'nome': 'Ripeti nuova Password', 'shortInput': true, 'type': 'PWD', 'required': true  }
    };

    this.arrayPulsanti = {
      'modificaEmail' : {'type' : 'edit', 'testo' : 'Modifica email', 'ML' : true},
      'modificaPassword' : {'type' : 'edit', 'testo' : 'Modifica password', 'ML' : true},
    };
  }

  ngOnInit(): void {
    this.myFormEmail = this.formComponentsService.createForm(this.arrayCampiEmail);
    this.myFormPassword = this.formComponentsService.createForm(this.arrayCampiPassword);
    this.formComponentsService.activateLoader(true);

    this.reservedAccessService.getUserBasicDataSubject().subscribe(data => {
      this.user = data;
      this.titoloScheda = "Gestione profilo";
      this.formComponentsService.activateLoader(false);
    });
  }

  onSubmitEmail() {
    if (!this.myFormEmail.valid) {
      this.formComponentsService.validateAllFormFields(this.myFormEmail);
      return false;
    }

    this.loading = true;

    this.service.sendEmail(this.myFormEmail.value)
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('success', 'Richiesta inviata con successo', 'Riceverai al nuovo indirizzo una email di conferma con un link di attivazione <b>da cliccare</b> per rendere effettiva la variazione inserita.');
        this.loading = false;
        this.pannelloModifica = '';
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onSubmitPassword() {
    if (!this.myFormPassword.valid) {
      this.formComponentsService.validateAllFormFields(this.myFormPassword);
      return false;
    }

    this.loading = true;

    this.service.sendPassword(this.myFormPassword.value)
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('success', 'La tua password è stata modificata');
        this.loading = false;
        this.pannelloModifica = '';
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onCancel() {
    this.componentsService.onCancel('/');
  }


  editForm(type) {
    this.pannelloModifica = type;
  }
}
