/******
Versione 1.0.20210401

https://www.npmjs.com/package/ngx-image-cropper
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-immagine',
  templateUrl: './campo-immagine.component.html',
  styleUrls: ['./campo-immagine.component.scss']
})
export class CampoImmagineComponent implements OnInit, OnDestroy {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  noteFormato = '';
  maintainAspectRatio = true;
  imgInvalidDim = false;
  imgLoadError = false;
  eliminaImmagine = false;

  inputClass: string;
  noteClass: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput(){
    return this.myForm.get(this.config.id);
  }

  get objImgAttuale(){
    return this.myForm.get(this.config.id + '_attuale');
  }

  get objImgPresente(){
    return this.myForm.get(this.config.id + '_presente');
  }

  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = "file";
    }
    if (!this.config.nome) {
      this.config.nome = "Sfoglia";
    }
    if (!this.config.minW) {
      this.config.minW = 0;
    }
    if (!this.config.minH) {
      this.config.minH = 0;
    }
    if (!this.config.maxW) {
      this.config.maxW = 1920;
    }
    if (!this.config.maxH) {
      this.config.maxH = 1080;
    }
    if (!this.config.ratio) {
      this.config.ratio = 1;
      this.maintainAspectRatio = false;
    }

    if (this.config.immagineAttuale) {
      this.myForm.get(this.config.id + '_presente').setValue(1);
    }

    if (this.config.minW || this.config.minH || this.config.ratio) {
      if (this.config.minW && this.config.minH) {
        this.noteFormato = 'Formato minimo richiesto: ' + this.config.minW + 'x' + this.config.minH;
      } else if (this.config.minW) {
        if (this.maintainAspectRatio) {
          this.noteFormato = 'Formato minimo richiesto: ' + this.config.inW + 'x' + Math.ceil(this.config.minW * this.config.ratio);
        } else {
          this.noteFormato = 'Larghezza minima richiesta: ' + this.config.minW;
        }
      } else if (this.config.minH) {
        if (this.maintainAspectRatio) {
          this.noteFormato = 'Formato minimo richiesto: ' + Math.ceil(this.config.minH / this.config.ratio) + 'x' + this.config.minH;
        } else {
          this.noteFormato = 'Altezza minima richiesta: ' + this.config.minH;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.annullaCaricamentoImmagine();
  }

  setCroppedImage(image) {
    this.croppedImage = image;
    this.myForm.controls[this.config.id + '_cropped'].setValue(this.croppedImage);
  }


  fileChangeEvent(event: any): void {
    this.showCropper = false;
    this.imgInvalidDim = false;
    this.imageChangedEvent = event;
    this.imgLoadError = false;
    this.setCroppedImage('');
  }

  eliminaImmagineChangeEvent(event: any): void {
    this.eliminaImmagine = event.target.checked;
    if (this.eliminaImmagine) {
      this.annullaCaricamentoImmagine();
      this.myForm.controls[this.config.id].disable();
    } else {
      this.myForm.controls[this.config.id].enable();
    }
  }


imageCropped(event: ImageCroppedEvent) {
  this.setCroppedImage(event.base64);
}

imageLoaded(event: any) {
  if ((event.original.size.width < this.config.minW) || (event.original.size.width < this.config.minW)) {
    this.myForm.get(this.config.id).setValue('');
    this.imgInvalidDim = true;
    return;
  }
  this.myForm.get(this.config.id + '_presente').setValue(1);
//  console.log(event);
//  console.log(event.original.size.width);
if (this.config.ratio) {
    this.showCropper = true;
  }
}

cropperReady($event) {
  $("#alertLoadingImage").hide();
//    console.log('Cropper ready');
}

loadImageFailed($event) {
  if (!this.imgInvalidDim) { // altrimenti compare messaggio errore caricamento anche quando l'immagine è scartata per dimensione inferiore al minimo richiesto
    this.imgLoadError = true;
  }
//    console.log('Load failed');
}


annullaCaricamentoImmagine() {
  this.showCropper = false;
  this.setCroppedImage(null);
  this.myForm.controls[this.config.id].setValue("");
}

}


