import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, mergeMap } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { TestClientService } from '../test-client.service';
import { Subscription, timer } from 'rxjs';
import { TestStatus } from '../../test/test-status';
import { ButtonService } from 'src/app/components/buttons/button.service';

@Component({
  selector: 'app-test-client',
  templateUrl: './test-client.component.html',
  styleUrls: ['./test-client.component.scss']
})
export class TestClientComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  titoloScheda: string = "Caricamento...";
  linkBase: string;
  prossimaDomanda: number = -1;
  waitForStart: boolean = false;
  restoreTest: boolean = false;
  pannelloPulsantiRisposta: boolean = false;
  pannelloAccessoTest: boolean = true;
  pannelloTestTerminato: boolean = false;
  private currentSub: Subscription;
  testStatus: TestStatus;
  rispostaData?: number = null;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: TestClientService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private buttonService: ButtonService,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'codice' : {'id' : 'codice', 'nome': 'Codice test', 'required': true, 'fullW': true},
      'nome' : {'id' : 'nome', 'nome': 'Nome', 'required': true, 'fullW': true},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    if (this.service.getUserCode() != undefined) {
      this.service.checkCodice(this.service.getUserCode())
      .subscribe(data => {
        if (data.testInCorso) {
          this.restoreTest = true;
        }
        else {
          this.restoreTest = false;
        }
/*
        this.clearInterface();
        this.pannelloPulsantiRisposta = true;
*/
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
    }
    //    this.formComponentsService.activateLoader(true);
  }

  onSendCodice() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendCodice(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.service.saveUserCode(response.codiceUtente);
        this.onResumeTest();
        this.notificationsService.show('not_OpOk');
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onResumeTest() {
    this.stopPolling();
    this.currentSub = timer(0, this.mainEngineService.TIMER_UPDATE).pipe(
      mergeMap(() => this.service.getStatus(this.service.getUserCode()))
    ).subscribe((response:any)=>{
      this.testStatus = response;
      console.log(response);
      if (!this.testStatus.active) {
        this.notificationsService.show('warning', 'Test non attivo', 'Attendere che il test venga attivato e riprovare');
        this.onExit();
      }
      else if (this.testStatus.terminato) {
        console.log("qua");
        this.stopPolling();
        this.clearInterface();
        this.pannelloTestTerminato = true;
      }
      else if (this.testStatus.domandaInCorso > 0) {
        this.clearInterface();
        this.pannelloPulsantiRisposta = true;
        if (this.testStatus.rispostaAttuale != undefined) {
          this.rispostaData = this.testStatus.rispostaAttuale;
        }
        else {
          this.rispostaData = null;
        }
      }
      else {
        this.clearInterface();
        this.waitForStart = true;
      }
    },(error)=>{
      this.mainEngineService.errorHandler(error);
    });
  }

  onClickRisposta(valore) {
    this.service.sendRisposta(this.service.getUserCode(), this.testStatus.codiceTest, this.testStatus.domandaInCorso, valore)
    .pipe(first())
    .subscribe(
      (response) => {
        this.rispostaData = valore;
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }



  ngOnDestroy () {
    this.stopPolling();
  }

  onExit() {
    const that = this;
    const obj = {
        onSuccess(arg) {
          that.stopPolling();
          that.clearInterface();
          that.pannelloAccessoTest = true;
        }
    };
    this.buttonService.confermaGenerico(obj);
  }

  stopPolling() {
    this.currentSub?.unsubscribe();
  }

  clearInterface() {
    this.waitForStart = false;
    this.pannelloPulsantiRisposta = false;
    this.pannelloAccessoTest = false;
    this.pannelloTestTerminato = false;
  }
}
