/******
Versione 1.0.20210401
******/

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-campo-hidden',
  templateUrl: './campo-hidden.component.html',
  styleUrls: ['./campo-hidden.component.scss']
})
export class CampoHiddenComponent implements OnInit {

  constructor() { }

  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput() {
    return this.myForm.get(this.config.id);
  }

  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'hidden';
    }
  }

}
