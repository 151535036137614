import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from '../test.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Domanda } from '../domanda';
import { Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DomandaStatus, RispostaStatus } from '../domanda-status';
import { TestStatus } from '../test-status';
import { ComponentsService } from 'src/app/components/component.service';

@Component({
  selector: 'app-test-engine',
  templateUrl: './test-engine.component.html',
  styleUrls: ['./test-engine.component.scss']
})
export class TestEngineComponent implements OnInit {
  @Input() testStatus: TestStatus;

  domanda: Domanda;
  private currentSub: Subscription;
  elencoRisposte: RispostaStatus[];
  timer: number;
  pause: boolean = false;
  testTerminato: boolean = false;
  mostraRisultati: boolean = false;
  mostraRisposta: boolean = false;

  constructor(
    private router: Router,
    private service: TestService,
    private formComponentsService: FormInputService,
    private componentsService: ComponentsService,
    private mainEngineService: MainEngineService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    if (this.testStatus.terminato == true) {
      this.testTerminato = true;
    }
    else {
      this.onNextQuestion(true);
    }
  }

  onStartTime() {
    this.service.resumeTest()
    .subscribe(
      (response) => {
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onPauseTime() {
    this.service.pauseTest()
    .subscribe(
      (response) => {
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onAddTime() {
    this.service.addTime()
    .subscribe(
      (response) => {
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onFinishTest() {
    this.stopPolling();

    this.service.finishTest()
    .subscribe(
      (response) => {
        this.testTerminato = true;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onMostraRisultatiTest() {
    this.mostraRisultati = true;
    this.onNextQuestion(true);
  }


  onMostraRisposta() {
    this.mostraRisposta = true;
  }

  onNextQuestion(firstQuestion = false) {
    this.formComponentsService.activateLoader(true);
    if (this.testTerminato) {
      this.mostraRisposta = false;
    }
    this.service.getDomanda(this.testStatus.codiceTest, !firstQuestion)
    .subscribe(data => {
      data._videoUrl = (data.videoCode != '') ? this.componentsService.sanitizeURL("https://www.youtube.com/embed/" + data.videoCode + "?autoplay=1") : "";
      this.domanda = data;
      if (firstQuestion && !this.testTerminato) {
        this.startPolling();
      }
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
      this.formComponentsService.activateLoader(false);
    });
  }


  onPrevQuestion() {
    this.formComponentsService.activateLoader(true);
    this.service.getPrevDomanda(this.testStatus.codiceTest)
    .subscribe(data => {
      data._videoUrl = (data.videoCode != '') ? this.componentsService.sanitizeURL("https://www.youtube.com/embed/" + data.videoCode + "?autoplay=1") : "";
      this.domanda = data;
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
      this.formComponentsService.activateLoader(false);
    });
  }

  startPolling() {
    this.currentSub = timer(0, this.mainEngineService.TIMER_UPDATE1).pipe(
      mergeMap(() => this.service.getStatusDomanda(this.testStatus.codiceTest, this.domanda.posizione))
    ).subscribe((response:DomandaStatus)=>{
      this.elencoRisposte = response.risposte;
      this.timer = response.tempoResiduoDomanda;
      this.pause = response.pause;
    },(error)=>{
      this.mainEngineService.errorHandler(error);
    });
  }

  stopPolling() {
    this.currentSub?.unsubscribe();
  }

  ngOnDestroy () {
    this.stopPolling();
  }
}
