import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class ProfiloService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "reservedAccess/";
  }

  getItem(): Observable<any> {
    return this.http.get<any>(this.apiUrl)
  }

  sendPassword(body: any) {
    return this.http.post<any>(this.apiUrl + "/op/modificaPassword" , body);
  }

  sendEmail(body: any) {
    return this.http.post<any>(this.apiUrl + "/op/modificaEmail" , body);
  }
}
