import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FotogalleryOptions } from './fotogallery-options';
import { MyPickerAdapterService } from './my-picker-adapter.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { GalleryImage } from './gallery-image';
import { UploaderCaptions } from 'ngx-awesome-uploader';
import { FormInputService } from '../scheda/formInput/form-input.service';
import { ComponentsService } from '../component.service';



@Component({
  selector: 'app-fotogallery',
  templateUrl: './fotogallery.component.html',
  styleUrls: ['./fotogallery.component.scss']
})
export class FotogalleryComponent implements OnInit {
  @Input() apiUrl: string;
  @Input() options: FotogalleryOptions;
  @Input() idItem: number;

  adapter: any;
  showUploadForm: boolean;
  loading = false;
  myForm: FormGroup;
  items: FormArray;
  uploadFormDirty = false;
  modifyFormDirty = false;
  dataSource: GalleryImage[];
  arrayPulsanti: any[any];
  arrayPulsantiBarra: any[any];
  arrayCampi: any[any];
  arrayElimina: boolean[] = [];
  titoloScheda: string = "Caricamento...";

  allowedExtensions = ['jpg', 'jpeg', 'png'];
  acceptExtensions = "." + this.allowedExtensions.join(", .")
  captions: UploaderCaptions = {
    dropzone: {
      title: "Trascina i files qui",
      or: "oppure",
      browse: "Cerca i files"
    },
    cropper: {
      crop: "Taglia",
      cancel: "Annulla"
    },
    previewCard: {
      remove: "Rimuovi",
      uploadError: "Errore di caricamento"
    }
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService,
    private componentsService: ComponentsService
  ) {
    this.arrayPulsanti = {
      'delete' : {'type' : 'delete', 'noMargin': true, 'testo': 'Elimina'},
      'showGallery' : {'type' : 'custom', 'testo' : 'Immagini caricate', 'colorClass' : 'light', 'icon': 'collections', 'actionTooltip' : 'Mostra le immagini caricate'},
      'showUpload' : {'type' : 'custom', 'testo' : 'Upload immagini', 'colorClass' : 'light', 'icon': 'file_upload', 'actionTooltip' : 'Carica nuove immagini'},
    };
  }

  ngOnInit(): void {
    this.onShowGallery();
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      filename: '',
      base64: '',
      cc: ''
    });
  }


  addItem(): void {
    this.items = this.myForm.get('items') as FormArray;
    this.items.push(this.createItem());

    this.myForm.get("i").setValue(+this.myForm.get("i").value + 1);
  }


  checkItemToUpload() {
    for (let i = 0; i <= +this.myForm.get("i").value; i++) {
      const actGroup = <FormGroup>this.myForm.controls['items'].get(i+"");
      if (actGroup.controls['base64'].value != '') {
        this.uploadFormDirty = true;
        return;
      }
    }
    this.uploadFormDirty = false;
  }

  public uploadSuccess(event): void {
    const actI = ""+this.myForm.get("i").value;

    const actGroup = <FormGroup>this.myForm.controls['items'].get(actI);

    actGroup.controls['base64'].setValue(event.uploadResponse.base64);
    actGroup.controls['filename'].setValue(event.uploadResponse.filename);
    actGroup.controls['cc'].setValue(event.uploadResponse.cc);

    this.uploadFormDirty = true;
    this.addItem();
  }


  public removeSuccess(event): void {
    this.checkItemToUpload();
  }


  public validationError (event): void {
    var titolo = '';
    var sottotitolo = '';
    switch(event.error) {
      case "EXTENSIONS":
        titolo = "Estensione non valida";
        sottotitolo = "Il file '" + event.file.name + "' ha una estensione non valida.\nLe estensione ammesse sono: " + this.allowedExtensions.join(", ");
        break;

      default:
        titolo = "Generico";
        sottotitolo = "Si è verificato un errore di tipologia sconosciuta (" + event.error + ")";
        break;
    }

    this.notificationsService.show('error', 'Errore: ' + titolo, sottotitolo);
  }


  onShowUpload() {
    this.myForm = this.formBuilder.group({
      idPadre: [this.idItem],
      i: [0],
      items: this.formBuilder.array([ this.createItem() ])
    });

    this.adapter = new  MyPickerAdapterService(this.http, this.apiUrl, this.myForm);
    this.showUploadForm = true;
  }

  onShowGallery() {
    this.showUploadForm = false;
    this.getElenco();
  }


  onSubmitUpload() {
    this.checkItemToUpload();
    if (!this.uploadFormDirty) {
      this.notificationsService.show('error', 'Errore', 'Non ci sono elementi da salvare');
      return;
    }

    this.loading = true;

    this.http.put<any>(this.apiUrl , this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.loading = false;
        this.onShowGallery();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onSubmitModify() {
    const that = this;
    if (!this.myForm.dirty) {
      this.notificationsService.show('error', 'Errore', 'Non ci sono modifiche da salvare');
      return;
    }

    this.loading = true;

    this.http.patch<any>(this.apiUrl , this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        that.notificationsService.show('not_OpOk');
        this.loading = false;
        this.onShowGallery();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  getElenco(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }
    this.http.get<any>(this.apiUrl+"/"+this.idItem)
    .subscribe(data => {
      this.dataSource = data.items;
      this.titoloScheda = "Gestione fotogallery - '" + data._titoloScheda + "'";

      this.arrayCampi = [];
      for (let key of Object.keys(this.dataSource)) {
        let myItem: GalleryImage = this.dataSource[key];
        this.arrayCampi['didascalia_' + myItem.id] = {'id' : 'didascalia_' + myItem.id, 'defValue' : myItem.didascalia};
        this.arrayCampi['elimina_' + myItem.id] = {'id' : 'elimina_' + myItem.id};
        this.arrayElimina[myItem.id] = false;
      }
      this.myForm = this.formComponentsService.createForm(this.arrayCampi);

      if (showLoader) {
        this.formComponentsService.activateLoader(false);
      }
    });
  }


  onClickElimina(id: number) {
    if (this.myForm.controls['elimina_' + id].value == "1") {
      this.myForm.controls['elimina_' + id].setValue("0");
      this.arrayElimina[id] = false;
    }
    else {
      this.myForm.controls['elimina_' + id].setValue("1");
      this.arrayElimina[id] = true;
    }
    this.myForm.markAsDirty();
  }


  onCancel() {
    this.componentsService.onCancel(this.options.backUrl);
  }

  onBack() {
    this.componentsService.onBack(this.options.backUrl);
  }

}
