import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Home',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'Domande',
      icon: 'fas fa-question-circle',
      link: '/domande',
    },
    {
      label: 'Svolgimento test',
      icon: 'fas fa-flag-checkered',
      link: '/test',
    },
    {
      label: 'Info versione',
      icon: 'fas fa-info-circle',
      link: '/infoVersione',
    },
];
