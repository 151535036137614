import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ConfigParams } from '../../class/config-params';
import { ReservedAccessService } from '../../reservedAccess/reserved-access.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  year: number = new Date().getFullYear();
  configParams: ConfigParams;
  layoutSize$ = new BehaviorSubject<string>("");


  constructor(
    private mainEngineService: MainEngineService,
    private reservedAccessService: ReservedAccessService
  ) {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      this.configParams = value;
    });
  }

  ngOnInit(): void {
    this.reservedAccessService.getParentLayout$()
    .subscribe(data => {
      this.layoutSize$.next(data);
    });
  }

}
