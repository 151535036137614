import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  sanitizeURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getActualRouteArray(): Array<string> {
    const actualRoute = this.router.url;
    return actualRoute.split('/');
  }

  tableFitHeight() {
	  if (!$(".table-container").length) {
		  return;
	  }
    const h1 = $(".table-container").offset().top;
    const h2 = $(".footer").offset().top;
    $(".table-container").css("max-height", h2-h1-20);
  }

  onCancel(backUrl) {
    Swal.fire({
      title: 'Sei veramente sicuro?',
      text: 'Eventuali modifiche non salvate verranno perse',
      showCancelButton: true,
      icon: 'warning'
    }).then((result) => {
      if (result.value) {
        this.router.navigate([backUrl]);
      }
    });
  }

  onBack(backUrl) {
    if (this.router.url == backUrl) {
      window.location.reload();
    }
    else {
      this.router.navigate([backUrl]);
    }
  }
}
