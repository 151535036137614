import { Component, Input, OnInit } from '@angular/core';
import { SliderOpzioni } from '../slider-opzioni';
import { SliderService } from '../slider.service';


@Component({
  selector: 'app-slider-elenco',
  templateUrl: './slider-elenco.component.html',
  styleUrls: ['./slider-elenco.component.scss']
})
export class SliderElencoComponent implements OnInit {
  @Input() options: SliderOpzioni;

  myService: any;
  columns: any[any];
  backUrl: string = '';

  constructor(
    private service: SliderService,
  ) {
    this.myService = this.service;

    this.columns = [
      { columnDef: 'immagine', header: 'Immagine', type:'img', cell: (element: any) => `${element.immagine}` },
    ];
  }

  ngOnInit(): void {
    this.service.setTipologia(this.options.tipologia);
    if (this.options.idPadre) {
      this.options.linkBase = "/" + this.options.tipologia + "/" + this.options.idPadre + "/slider";
      this.service.setIdPadre(this.options.idPadre);
      this.backUrl = "/" + this.options.tipologia + "/lastItem/"+this.options.idPadre;
    }
  }
}
