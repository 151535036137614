import { LoaderService } from '../../../service/loader.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MainEngineService } from '../../../service/main-engine.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { FormInputService } from '../../scheda/formInput/form-input.service';
import { ReservedAccessService } from '../reserved-access.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  arrayCampi: any[any];
  loading = false;
  arrayPulsanti: any[any];

  constructor(
    private formBuilder: FormBuilder,
    public mainEngineService: MainEngineService,
    private formComponentsService: FormInputService,
    private router: Router,
    private notificationsService: NotificationsService,
    private loader: LoaderService,
    private reservedAccessService: ReservedAccessService
  ) {
    this.arrayPulsanti = {
      'accedi' : {'type' : 'custom', 'testo' : 'ACCEDI', 'colorClass' : 'primary', 'size' : 'L', 'lockOnLoading' : true},
      'registrati' : {'type' : 'custom', 'testo' : 'REGISTRATI', 'colorClass' : 'warning', 'size' : 'L'},
    };

    this.loader.stopSpinner(); // resetto eventuali spin rimasti appesi


  }

  ngOnInit(): void {
    this.reservedAccessService.setParentLayout$("small");
    if (this.mainEngineService.globalUrlArray && (this.mainEngineService.globalUrlArray.length > 1)) {
      switch (this.mainEngineService.globalUrlArray[1]) {
        case 'confermaPassword':
          if (this.mainEngineService.globalUrlArray.length !== 4) {
            this.notificationsService.show('error', '', 'Il link utilizzato per questa richiesta non è più valido.<br>Ripetere nuovamente la procedura');
          } else {
            this.confermaPassword(Number(this.mainEngineService.globalUrlArray[2]), this.mainEngineService.globalUrlArray[3]);
          }
        break;

        case 'confermaEmail':
          if (this.mainEngineService.globalUrlArray.length !== 4) {
            this.notificationsService.show('error', '', 'Il link utilizzato per questa richiesta non è più valido.<br>Ripetere nuovamente la procedura');
          } else {
            localStorage.clear();
            this.confermaEmail(Number(this.mainEngineService.globalUrlArray[2]), this.mainEngineService.globalUrlArray[3]);
          }
        break;
      }
    }
    this.arrayCampi = {'email' : {'id' : 'username', 'nome': 'Username', 'fullWidth': true, 'required': true},
                    'password' : {'id' : 'password', 'nome': 'Password', 'fullWidth': true, 'required': true}
  };
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
  }

  confermaPassword(idUser: number, token: string) {
    this.reservedAccessService.confermaPassword(idUser, token)
    .pipe(first())
    .subscribe(
      data => {
        this.notificationsService.show('success', 'Attivazione riuscita', 'Puoi effettuare il login con le credenziali indicate nella mail ricevuta');
      },
      error => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  confermaEmail(idUser: number, token: string) {
    this.reservedAccessService.confermaEmail(idUser, token)
    .pipe(first())
    .subscribe(
      data => {
        this.notificationsService.show('success', 'La richiesta è stata confermata', 'Puoi effettuare il login accedendo con il nuovo indirizzo email');
      },
      error => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  signIn() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.reservedAccessService.login(this.myForm.getRawValue().username, this.myForm.getRawValue().password)
    .pipe(first())
    .subscribe(
      data => {
        this.reservedAccessService.saveUserToken(data.jwt);
        this.reservedAccessService.setUserBasicData(data);
        this.reservedAccessService.activateTimerUserUpdate();
        this.router.navigate(['/home']);
      },
      error => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }
}
