import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestEngineComponent } from './test-engine/test-engine.component';
import { TestPreHomeComponent } from './test-pre-home/test-pre-home.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [

    TestEngineComponent,
    TestPreHomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule
  ]
})
export class TestModule { }
