/******
Versione 1.0.20210401
https://www.npmjs.com/package/ng-select2-component

******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, SimpleChange, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormInputService } from '../form-input.service';
import { Select2Data, Select2Option } from 'ng-select2-component';


@Component({
  selector: 'app-campo-select',
  templateUrl: './campo-select.component.html',
  styleUrls: ['./campo-select.component.scss']
})
export class CampoSelectComponent implements OnInit {
  @Output() onSelectEmitter = new EventEmitter<{value: any}>();

  @Input() myForm: FormGroup;
  @Input() config: any;
  @Input() options: any[];

  inputClass: string;
  noteClass: string;
  data: Select2Data;
  ready: boolean = false;
  firstLoad = true; // utilizzato per il reset della select nel caso in cui, successivamente al primo caricamento, variano gli elementi della select

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'select';
    }
    if (!this.config.nome) {
      this.config.nome = 'Select';
    }
    if (this.config.shortInput === undefined) {
      this.config.shortInput = false;
    }
    if (this.config.inputClass !== undefined) {
      this.inputClass = this.config.inputClass
    }
    if (this.config.noteClass !== undefined) {
      this.noteClass = this.config.noteClass
    }
    if (!this.config.multiple) {
      this.config.multiple = false;
    }

    if (this.config.readonly) {
      this.myForm.get(this.config.id).disable();
    }

   // set initial selection
//   this.optionsCtrl.setValue(this.options[1]);
  }


  triggerOnSelect() {
    this.myForm.markAsDirty();
  }

  triggerOnUpdate() {
    if (this.objInput.value == '') {
      return;
    }
    this.onSelectEmitter.emit(this.objInput.value);
  }


  public ngOnChanges(changes: SimpleChanges): void {
    let defValue = '';
    if ('options' in changes) {
      this.ready = false;
      let arrayData = changes['options'].currentValue;
      if (arrayData == undefined) {
        return;
      }
      let optionsArray = [];
      for (let key in arrayData) {
        optionsArray.push({value: arrayData[key].id,
                      label: arrayData[key].denominazione,
                      data: {name: arrayData[key].denominazione},
                      templateId: arrayData[key].id,
                      id: arrayData[key].id
        });
        if (arrayData[key].defValue === true) {
          defValue = arrayData[key].id;
        }
      }
      this.data = [{'label' : '', 'data' : '',
        'options' : optionsArray}];
      }
      if (!this.firstLoad) {
        this.objInput.setValue(defValue);
      }
      else {
        this.firstLoad = false;
      }
      this.ready = true;
  }
}
