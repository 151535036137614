import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestService } from '../test.service';
import { TestStatus } from '../test-status';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { first, mergeMap } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { Iscritto } from '../iscritto';

@Component({
  selector: 'app-test-pre-home',
  templateUrl: './test-pre-home.component.html',
  styleUrls: ['./test-pre-home.component.scss']
})
export class TestPreHomeComponent implements OnInit {
  testStatus: TestStatus;
  testAvviato: boolean = false;
  testEngine: boolean = false;
  private currentSub: Subscription;
  elencoIscritti: Iscritto[] = [];

  constructor(
    private router: Router,
    private service: TestService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.formComponentsService.activateLoader(true);

    this.service.getStatus()
    .subscribe(data => {
      this.testStatus = data;
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
      this.formComponentsService.activateLoader(false);
    });
  }

  stopPolling() {
    this.currentSub?.unsubscribe();
  }

  ngOnDestroy () {
    this.stopPolling();
  }


  avviaNuovoTest(): void {
    this.formComponentsService.activateLoader(true);

    this.service.startNewTest()
    .pipe(first())
    .subscribe(
      (response) => {
        this.testStatus = response;
        this.riprendiTest();
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  riprendiTest(): void {
    this.service.getStatus()
    .subscribe(data => {
      this.testStatus = data;
      this.formComponentsService.activateLoader(false);
      this.testAvviato = true;
      if (this.testStatus.domandaInCorso > 0) {
        this.avviaTestEngine();
      }
      else {
        this.getIscritti();
      }
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
      this.formComponentsService.activateLoader(false);
    });

  }


  avviaTestEngine(): void {
    this.stopPolling();
    this.testEngine = true;
  }


  getIscritti() {
    this.currentSub = timer(0, this.mainEngineService.TIMER_UPDATE).pipe(
      mergeMap(() => this.service.getIscritti(this.testStatus.codiceTest))
    ).subscribe((response:any)=>{
      this.elencoIscritti = response;
    },(error)=>{
      this.mainEngineService.errorHandler(error);
    });
  }


}
