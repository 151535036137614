import { Component, OnInit, Inject, EventEmitter, Output, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigParams } from '../../class/config-params';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { UserLogged } from '../../reservedAccess/user-logged';
import { ReservedAccessService } from '../../reservedAccess/reserved-access.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  configParams: ConfigParams;
  user: UserLogged;

  element: any;
  valueset: any;
  newVersionAlert: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private mainEngineService: MainEngineService,
    private reservedAccessService: ReservedAccessService
  ) { }

  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      if (value) {
        this.configParams = value;
        this.newVersionAlert = (value.versionMismatch) ? true : false;
      }
    });
    this.reservedAccessService.getUserBasicDataSubject().subscribe(data => {
      this.user = data;
    });

    this.element = document.documentElement;
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  logout() {
    Swal.fire({
        title: 'Vuoi veramente uscire?',
        icon: 'warning',
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          this.reservedAccessService.logout();
        }
    });
}

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    const attribute = document.body.getAttribute('data-sidebar-size');
    event.preventDefault();
    if (attribute == 'lg') {
      document.body.setAttribute("data-sidebar-size", "md");
    }
    else {
      document.body.setAttribute("data-sidebar-size", "lg");
    }
    this.mobileMenuButtonClicked.emit();
  }

}
