import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-main-layout',
  templateUrl: './local-main-layout.component.html',
})
export class LocalMainLayoutComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }


}
