import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-users-elenco',
  templateUrl: './users-elenco.component.html',
  styleUrls: ['./users-elenco.component.scss']
})
export class UsersElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.myService = this.userService;

        this.columns = [
          { columnDef: 'nome', header: 'Nome',    cell: (element: any) => `${element.nome}` },
          { columnDef: 'email',     header: 'Email',   cell: (element: any) => `${element.email}`     },
          { columnDef: 'stato',     header: 'Stato',   cell: (element: any) => `<span style='color: ${element.statoColor}'>${element.stato}</span>`     },
          { columnDef: 'lastRequest',     header: 'Last request',   cell: (element: any) => `${element.lastRequest}`     },
        ];
        this.options = {
          linkBase: this.userService.linkBase
        }
      });
    }

  ngOnInit(): void {
  }
}
