import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { FormInputService } from '../../scheda/formInput/form-input.service';
import { ReservedAccessService } from '../reserved-access.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  myForm: FormGroup;
  arrayCampi: any[any];
  loading = false;
  pulsanteForm: any;

  constructor(
    private router: Router,
    private notificationsService: NotificationsService,
    private formComponentsService: FormInputService,
    private reservedAccessService: ReservedAccessService
  ) {
    this.arrayCampi = {'email' : {'id' : 'email', 'nome': 'Email', 'fullWidth': true, 'required': true}};
    this.pulsanteForm = {'type' : 'custom', 'testo' : 'Procedi', 'colorClass' : 'primary', 'size' : 'L', 'lockOnLoading' : true};
  }

  ngOnInit(): void {
    this.reservedAccessService.setParentLayout$("small");
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
  }

  recover() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.reservedAccessService.recover(this.myForm.getRawValue().email)
    .pipe(first())
    .subscribe(
      data => {
        this.notificationsService.show('success', 'Recupero effettuato con successo', 'Riceverai all\'indirizzo email indicato la nuova password e la procedura per attivarla. <br /><br><b>ATTENZIONE:</b> La nuova password sarò valida solo <b>DOPO</b> aver cliccato il link di attivazione presente nella mail ricevuta');
        this.router.navigate(['/login']);
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.loading = false;
      }
    );
  }
}
