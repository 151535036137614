/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-radio',
  templateUrl: './campo-radio.component.html',
  styleUrls: ['./campo-radio.component.scss']
})
export class CampoRadioComponent implements OnInit {
  inputClass: string;
  noteClass: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;
  @Input() onClick: () => void;

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'radio';
    }

    if (!this.config.inline) {
      this.config.inline = false;
    }
  }

  triggerOnClick(value) {
    if (this.config.onClick != undefined) {
      this.config.onClick(value);
    }
  }
}
