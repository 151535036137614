import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomandeService } from '../domande.service';


@Component({
  selector: 'app-domande-elenco',
  templateUrl: './domande-elenco.component.html',
  styleUrls: ['./domande-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DomandeElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  idPadre = 0;

  constructor(
    private service: DomandeService,
    private route: ActivatedRoute,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'testo', header: 'Testo',    cell: (element: any) => `${element.testo}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
        }
      });
  }

  ngOnInit(): void {
  }

}
