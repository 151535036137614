import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-white-layout',
  templateUrl: './local-white-layout.component.html',
})
export class LocalWhiteLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
