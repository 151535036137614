import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ComponentsService } from '../../component.service';
import { FormInputService } from '../../scheda/formInput/form-input.service';
import { SliderImage } from '../slider-image';
import { SliderOpzioni } from '../slider-opzioni';
import { SliderService } from '../slider.service';

@Component({
  selector: 'app-slider-dettaglio',
  templateUrl: './slider-dettaglio.component.html',
  styleUrls: ['./slider-dettaglio.component.scss']
})
export class SliderDettaglioComponent implements OnInit {
  @Input() options: SliderOpzioni;

  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: SliderImage;
  titoloScheda: string;
  arrayData: any[any];
  elencoLink: string;
  $attivaLink: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: SliderService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.$attivaLink = new BehaviorSubject(false);

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    let parametriImmagine = {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG','required': true};
    if (this.options.dimensione) {
      const dummy = this.options.dimensione.split("x");
      parametriImmagine['ratio'] = +dummy[0]/+dummy[1];
      parametriImmagine['minW'] = +dummy[0];
      parametriImmagine['minH'] = +dummy[1];
    }

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'immagine' : parametriImmagine,
    };

    if (this.options.idPadre != 0) {
      this.arrayCampi['idPadre'] = {'id' : 'idPadre', 'type': 'NUM'};
    }
    if (this.options.campoTitolo) {
      this.arrayCampi['titolo'] = {'id' : 'titolo', 'nome': 'Titolo', 'required': false};
    }
    if (this.options.campoSottotitolo) {
      this.arrayCampi['sottotitolo'] = {'id' : 'sottotitolo', 'nome': 'Sottotitolo', 'required': false};
    }
    if (this.options.campoLink) {
      this.arrayCampi['attivaLink'] = {'id' : 'attivaLink', 'nome': 'Attiva pulsante link', 'type': 'CHK', 'required': false},
      this.arrayCampi['link'] = {'id' : 'link', 'nome': 'Link', 'type': 'URL', 'required': false};
      this.arrayCampi['titoloLink'] = {'id' : 'titoloLink', 'nome': 'Titolo pulsante link', 'required': false};
    }

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        if (params.idPadre) {
          this.myForm.controls.idPadre.setValue(params.idPadre);
          this.elencoLink = '/' + this.options.tipologia + '/' + params.idPadre + '/slider';
        }
        else {
          this.elencoLink = '/' + this.options.linkBase;
        }
        if (params.id != '0') {
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.formComponentsService.activateLoader(false);
          });
          this.titoloScheda = "Modifica elemento";
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });

      if (this.options.campoLink) {
        this.myForm.controls['attivaLink'].valueChanges.subscribe(change => {
        this.$attivaLink.next(change);

        if (change) { // shopMinore
          this.myForm.get('link').setValidators([Validators.required]);
          this.myForm.get('link').setValidators([Validators.pattern(this.formComponentsService.linkPattern)]);
          this.myForm.get('titoloLink').setValidators([Validators.required]);
        }
        else {
          this.myForm.get('link').clearValidators();
          this.myForm.get('titoloLink').clearValidators();
        }
        this.myForm.get('link').updateValueAndValidity();
        this.myForm.get('titoloLink').updateValueAndValidity();
      });
    }

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.elencoLink]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.elencoLink + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
        this.router.navigate([this.elencoLink]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.elencoLink);
  }

  onBack() {
    this.componentsService.onBack(this.elencoLink);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  public isLinkFieldActive() {
    return (this.$attivaLink.value);
  }
}
