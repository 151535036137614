/******
Versione 1.0.20210427

// per farlo funzionare dentro mat-tab-group:
https://stackoverflow.com/questions/56985764/angular-mat-tab-group-and-tinymce


******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-rich-text',
  templateUrl: './campo-rich-text.component.html',
  styleUrls: ['./campo-rich-text.component.scss']
})
export class CampoRichTextComponent implements OnInit {
  inputClass: string;
  noteClass: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  private editorSubject: Subject<any> = new AsyncSubject();

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'richText';
    }
    if (!this.config.nome) {
      this.config.nome = 'Testo';
    }
  }
}
