import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsComponent } from './buttons/buttons.component';
import { ElencoComponent } from './elenco/elenco.component';
import { TestataComponent } from './testata/testata.component';
import { CategorieElencoComponent } from './elenco/categorie/categorie-elenco/categorie-elenco.component';
import { CategorieSchedaComponent } from './elenco/categorie/categorie-scheda/categorie-scheda.component';
import { FotogalleryComponent } from './fotogallery/fotogallery.component';
import { NavbarComponent } from './modules/navbar/navbar.component';
import { SidebarComponent } from './modules/sidebar/sidebar.component';
import { FooterComponent } from './modules/footer/footer.component';
import { CampiSeoComponent } from './scheda/campi-seo/campi-seo.component';
import { PulsantiSchedaComponent } from './scheda/pulsanti-scheda/pulsanti-scheda.component';
import { CampoEmailComponent } from './scheda/formInput/campo-email/campo-email.component';
import { CampoPasswordComponent } from './scheda/formInput/campo-password/campo-password.component';
import { CampoTestoComponent } from './scheda/formInput/campo-testo/campo-testo.component';
import { CampoTextAreaComponent } from './scheda/formInput/campo-text-area/campo-text-area.component';
import { CampoImmagineComponent } from './scheda/formInput/campo-immagine/campo-immagine.component';
import { CampoHiddenComponent } from './scheda/formInput/campo-hidden/campo-hidden.component';
import { CampoSelectComponent } from './scheda/formInput/campo-select/campo-select.component';
import { CampoRadioComponent } from './scheda/formInput/campo-radio/campo-radio.component';
import { CampoNumeroComponent } from './scheda/formInput/campo-numero/campo-numero.component';
import { CampoRichTextComponent } from './scheda/formInput/campo-rich-text/campo-rich-text.component';
import { CampoDataComponent } from './scheda/formInput/campo-data/campo-data.component';
import { CampoPeriodoComponent } from './scheda/formInput/campo-periodo/campo-periodo.component';
import { CampoCheckboxComponent } from './scheda/formInput/campo-checkbox/campo-checkbox.component';
import { SliderDettaglioComponent } from './slider/slider-dettaglio/slider-dettaglio.component';
import { SliderElencoComponent } from './slider/slider-elenco/slider-elenco.component';
import { UsersElencoComponent } from './users/users-elenco/users-elenco.component';
import { UsersDettaglioComponent } from './users/users-dettaglio/users-dettaglio.component';
import { SafePipe } from './safe.pipe';
import { getPaginatorIntl } from './elenco/paginator-translate';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NotifierModule } from 'angular-notifier';
import { Select2Module } from 'ng-select2-component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './loader/loader.component';
import { NotifierComponent } from './notifier/notifier.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginLayoutModule } from './layouts/login-layout/login-layout.module';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { SocialsComponent } from './socials/socials.component';
import { Page404Component } from './page404/page404.component';
import { CampoFileComponent } from './scheda/formInput/campo-file/campo-file.component';
import { CampoOrarioComponent } from './scheda/formInput/campo-orario/campo-orario.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { ProfiloComponent } from './profilo/profilo.component';
import { InfoVersioneComponent } from './info-versione/info-versione.component';

@NgModule({
  declarations: [
    ButtonsComponent,
    ElencoComponent,
    TestataComponent,
    CategorieElencoComponent,
    CategorieSchedaComponent,
    FotogalleryComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    CampiSeoComponent,
    PulsantiSchedaComponent,
    CampoEmailComponent,
    CampoPasswordComponent,
    CampoTestoComponent,
    CampoTextAreaComponent,
    CampoImmagineComponent,
    CampoHiddenComponent,
    CampoSelectComponent,
    CampoRadioComponent,
    CampoNumeroComponent,
    CampoRichTextComponent,
    CampoDataComponent,
    CampoPeriodoComponent,
    CampoCheckboxComponent,
    SliderDettaglioComponent,
    SliderElencoComponent,
    UsersElencoComponent,
    UsersDettaglioComponent,
    SafePipe,
    LoaderComponent,
    NotifierComponent,
    SocialsComponent,
    Page404Component,
    CampoFileComponent,
    CampoOrarioComponent,
    DropdownButtonComponent,
    ProfiloComponent,
    InfoVersioneComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    FilePickerModule,
    ImageCropperModule,
    EditorModule,
    Select2Module,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    SimplebarAngularModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NoopAnimationsModule,
    LoginLayoutModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      },
      theme: 'material',
      behaviour: {
        autoHide: 3000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
      }

    }),
  ],
  exports: [
    ButtonsComponent,
    ElencoComponent,
    TestataComponent,
    CategorieElencoComponent,
    CategorieSchedaComponent,
    FotogalleryComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    CampiSeoComponent,
    PulsantiSchedaComponent,
    CampoEmailComponent,
    CampoPasswordComponent,
    CampoTestoComponent,
    CampoTextAreaComponent,
    CampoImmagineComponent,
    CampoHiddenComponent,
    CampoSelectComponent,
    CampoRadioComponent,
    CampoNumeroComponent,
    CampoRichTextComponent,
    CampoDataComponent,
    CampoPeriodoComponent,
    CampoCheckboxComponent,
    CampoFileComponent,
    CampoOrarioComponent,
    SliderDettaglioComponent,
    SliderElencoComponent,
    UsersElencoComponent,
    UsersDettaglioComponent,
    SafePipe,
    LoaderComponent,
    NotifierComponent,
    LoginLayoutComponent
  ],
  providers : [
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
    ],

})
export class ComponentsModule { }
