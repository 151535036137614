import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { TestStatus } from './test-status';
import { Domanda } from './domanda';
import { Iscritto } from './iscritto';
import { DomandaStatus } from './domanda-status';

@Injectable({
  providedIn: 'root'
})
export class TestService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/test/';
  }


  getStatus(): Observable<TestStatus> {
    return this.http.get<TestStatus>(this.apiUrl + 'op/getStatus')
  }

  getIscritti(codiceTest: string): Observable<Iscritto[]> {
    return this.http.get<Iscritto[]>(this.apiUrl + 'op/getIscritti?codiceTest=' + codiceTest)
  }

  startNewTest(): Observable<TestStatus> {
    return this.http.post<TestStatus>(this.apiUrl + 'op/startNewTest', {})
  }

  resumeTest(): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'op/resumeTest', {})
  }

  pauseTest(): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'op/pauseTest', {})
  }

  addTime(): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'op/addTime', {})
  }

  getDomanda(codiceTest: string, next: boolean = false): Observable<Domanda> {
    return this.http.get<Domanda>(this.apiUrl + 'op/getDomanda?codiceTest=' + codiceTest + '&next=' + next)
  }

  getPrevDomanda(codiceTest: string): Observable<Domanda> {
    return this.http.get<Domanda>(this.apiUrl + 'op/getDomanda?codiceTest=' + codiceTest + '&prev=true')
  }

  getStatusDomanda(codiceTest: string, domanda: number): Observable<DomandaStatus> {
    return this.http.get<DomandaStatus>(this.apiUrl + 'op/getStatusDomanda?codiceTest=' + codiceTest + '&domanda=' + domanda)
  }

  finishTest(): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'op/finishTest', {})
  }
}
