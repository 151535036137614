import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OpzioniElenco } from '../elenco/opzioni-elenco';
import { SliderImage } from './slider-image';


@Injectable({
  providedIn: 'root'
})
export class SliderService {

  apiUrl: string;
  addIdPadre: string  = '';
  addTipologia: string  = '';

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "slider/";
  }

  setTipologia(tipologia: string) {
    this.addTipologia = tipologia + "/";
  }

  setIdPadre(idPadre: number) {
    this.addIdPadre = "?idPadre=" + idPadre;
  }


  getElenco(): Observable<any> {
    return this.http.get<any>(this.apiUrl + this.addTipologia + this.addIdPadre)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + this.addTipologia + 'op/opzioni' + this.addIdPadre)
  }


  getItem(id: number): Observable<SliderImage> {
    return this.http.get<SliderImage>(this.apiUrl + this.addTipologia + id)
  }

  deleteItem(id: number) {
    return this.http.delete<SliderImage>(this.apiUrl + this.addTipologia + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + this.addTipologia , body);
  }

  toggleVisibile(id: number) {
    const tipologia = 'visibile';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }

  toggleEvidenza(id: number) {
    const tipologia = 'evidenza';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }

  toggleEvidenza_pp(id: number) {
    const tipologia = 'evidenza_pp';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }

  toggleEvidenza_sp(id: number) {
    const tipologia = 'evidenza_sp';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }

  changePosition(id: number, newPos: number) {
    const tipologia = 'posizione';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia, newPos})
  }

}
