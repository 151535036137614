import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MainEngineService } from './main-engine.service';
import { ReservedAccessService } from '../components/reservedAccess/reserved-access.service';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
    constructor(
      private mainEngineService: MainEngineService,
      private reservedAccessService: ReservedAccessService
      ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const jwt = this.reservedAccessService.getUserToken();
        const isApiUrl = request.url.startsWith(this.mainEngineService.baseUrl);

        if (jwt && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${jwt}`
                },
                setParams: {
                    appVersion: this.mainEngineService.VERSION
                }
            });
        }

        return next.handle(request);
    }
}
