import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ReservedAccessService } from '../components/reservedAccess/reserved-access.service';
import { Router } from '@angular/router';



@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(
      private reservedAccessService: ReservedAccessService,
      private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            this.reservedAccessService.badSessionLogout(err.error.message);
          }
          if (err.status === 403) {
            this.router.navigate(['/404']);
          }

          return throwError(err);
        }));
    }
}
