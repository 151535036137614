/***
 * https://www.npmjs.com/package/angular-notifier
 *
 *
 */

import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private readonly notifier: NotifierService;

  constructor(
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  show(type: string, title = '', message = '') {
    switch (type) {
      case 'error':
        if (!title) {
          title = 'ERRORE!';
        }
        Swal.fire({
          title: title,
          html: message,
          icon: "error"
        });
        break;

      case 'warning':
        if (!title) {
          title = 'ATTENZIONE!';
        }
        Swal.fire({
          title: title,
          html: message,
          icon: "warning"
        });
        break;

      case 'success':
        Swal.fire({
          title: title,
          html: message,
          icon: "success"
        });
        break;

      case 'not_OpOk':
        this.notifier.show({
          type: 'success',
          message: 'Operazione completata con successo',
        });
        break;
    }
  }
}
