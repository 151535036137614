import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ButtonService } from '../../buttons/button.service';

@Component({
  selector: 'app-pulsanti-scheda',
  templateUrl: './pulsanti-scheda.component.html',
  styleUrls: ['./pulsanti-scheda.component.scss']
})
export class PulsantiSchedaComponent implements OnInit {
  @Input() loading: boolean;
  @Input() dirty: boolean = false;
  @Input() forceShowCancel: boolean = false;

  @Output() public onSubmitEmitter = new EventEmitter<void>();
  @Output() public onSaveEmitter = new EventEmitter<void>();
  @Output() public onSubmitAndNewEmitter = new EventEmitter<void>();
  @Output() public onCancelEmitter = new EventEmitter<void>();

  isOnSubmitUsed: boolean = false;
  isonCancelUsed: boolean = false;
  isonSaveUsed: boolean = false;
  isonSubmitAndNewUsed: boolean = false;

  constructor(
    private buttonService: ButtonService,
  ) {
  }

  ngOnInit(): void {
    // Alternativa per aggiornamenti futuri:
    // https://stackoverflow.com/questions/33441393/is-there-a-way-to-check-for-output-wire-up-from-within-a-component-in-angular
    this.isOnSubmitUsed = this.onSubmitEmitter.observers.length > 0;
    this.isonCancelUsed = this.onCancelEmitter.observers.length > 0;
    this.isonSaveUsed = this.onSaveEmitter.observers.length > 0;
    this.isonSubmitAndNewUsed = this.onSubmitAndNewEmitter.observers.length > 0;
  }

  submit() {
    this.onSubmitEmitter.emit(null);
  }

  save() {
    this.onSaveEmitter.emit(null);
  }

  submitAndNew() {
    this.onSubmitAndNewEmitter.emit(null);
  }

  cancel() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.onCancelEmitter.emit(null);
      }
    };

    this.buttonService.confermaAnnulla(obj);

  }

}
