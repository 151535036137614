/******
Versione 1.0.20220829

ATTENZIONE: non funziona, non ho terminato di testarlo perchè nelle tabelle il dropdown menù si apre ma non si vede (rimane confinato dentro l'altezza della riga)

******/

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {
  @Input() config: any;
  @Input() type: string;
  @Input() forceDisabled: boolean = false;

  ML: boolean = false;
  size: string = '';
  testo = "Opzioni";
  colorClass: string = "info";
  icon: string = "mdi mdi-chevron-down";
  vociMenu: any[any];

  constructor() { }

  ngOnInit(): void {
    if (this.config) {
      console.log(this.config.vociMenu);
      this.vociMenu = this.config.vociMenu;
      this.type = this.config.type;
      if (this.config.hideText) {
        this.testo = "mdi mdi-menu-open"
      };
      if (this.config.colorClass) {
        this.colorClass = this.config.colorClass;
      }
    }
  }

}
