/******
Versione 1.0.20220630
******/
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-orario',
  templateUrl: './campo-orario.component.html',
  styleUrls: ['./campo-orario.component.scss']
})
export class CampoOrarioComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  min: string;
  max: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.shortInputClass;
      this.noteClass = this.formComponentsService.shortNoteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'orario';
    }
    if (!this.config.nome) {
      this.config.nome = 'Orario';
    }
    if (this.config.min) {
      this.min = this.config.min;
    }
    if (this.config.max) {
      this.min = this.config.max;
    }
  }
}
