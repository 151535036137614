import { Component, OnInit } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ConfigParams } from '../../class/config-params';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  configParams: ConfigParams;
  versione: string;

  constructor(
    private mainEngineService: MainEngineService,
  ) { }

  year: number = new Date().getFullYear();

  ngOnInit(): void {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      this.configParams = value;
    });
    this.versione = this.mainEngineService.VERSION;
  }

}
