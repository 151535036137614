import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { ComponentsModule } from '../components.module';
import { RouterModule } from '@angular/router';
import { AddCustomLoginModule } from 'src/app/custom/add-custom-login/add-custom-login.module';


@NgModule({
  declarations: [
    LoginComponent,
    RecoverComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    AddCustomLoginModule
  ],
  exports: [
    LoginComponent,
    RecoverComponent,
  ]
})
export class ReservedAccessModule { }
