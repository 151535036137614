import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { interval } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { UserLogged } from './user-logged';

@Injectable({
  providedIn: 'root'
})
export class ReservedAccessService {
  userBasicDataObject: BehaviorSubject<UserLogged>;
  intervallTimer: any;
  linkBase: string = "reservedAccess";
  apiCommonOpUrl: string;
  apiUrl: string;
  parentLayout$: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + '/';
    this.apiCommonOpUrl = this.mainEngineService.baseUrl + 'customOperations/';
    this.userBasicDataObject = new BehaviorSubject<UserLogged>(null);
    this.parentLayout$ = new BehaviorSubject<string>('small');
  }

  getParentLayout$(): Observable<string> {
    return this.parentLayout$.asObservable();
  }

  setParentLayout$(value: string): void {
    this.parentLayout$.next(value);
  }

  confermaPassword(idUser: number, token: string) {
    return this.http.post<any>(this.apiUrl + 'op/attivaPassword/' , {idUser, token})
    .pipe(map(data => {
      return data;
    }));
  }


  confermaEmail(idUser: number, token: string) {
    return this.http.post<any>(this.apiUrl + 'op/confermaEmail/' , {idUser, token})
    .pipe(map(data => {
      return data;
    }));
  }


  recover(email: string) {
    return this.http.post<any>(this.apiUrl + 'op/recover/' , {email})
    .pipe(map(data => {
      return data;
    }));
  }


  login(username: string, password: string) {
    return this.http.post<any>(this.apiUrl + 'op/signIn/' , {username, password})
    .pipe(map(data => {
      return data;
    }));
  }

  logout() {
    localStorage.clear();
    if (this.intervallTimer) {
      this.intervallTimer.unsubscribe();
    }
    this.router.navigate(['/login']);
  }

  badSessionLogout(errorMsg) {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  saveUserToken(token: string) {
    localStorage.setItem('token', token);
  }

  getUserToken(): string {
    return localStorage.getItem('token');
  }

  isUserLoggedIn() {
    return !!this.getUserToken();
  }

  setUserBasicData(userJson: UserLogged): void {
    localStorage.setItem('user', JSON.stringify(userJson));

    this.userBasicDataObject.next(userJson);
  }

  getUserBasicData(): void {
    const user = localStorage.getItem('user');
    if (user) {
      const userJson = JSON.parse(user);
      this.userBasicDataObject.next(userJson);
    }
  }


  getUserBasicDataFromLocalStorage(): UserLogged {
    const user = localStorage.getItem('user');
    const userJson = JSON.parse(user);
    return userJson;
  }


  activateTimerUserUpdate() {
    if (!this.isUserLoggedIn()) {
      return;
    }
    this.intervallTimer = interval(600000).subscribe(x => { // will execute every 10 minutes
      this.http.get<any>(this.apiUrl + 'op/getUserBasicData/').subscribe(data => {
        this.setUserBasicData(data);
      });
    });
  }

  refreshUserData() {
    this.http.get<any>(this.mainEngineService.baseUrl + 'getUserBasicData/')
    .subscribe(data => {
      this.setUserBasicData(data);
    });
  }

  getUserBasicDataSubject(): Observable<UserLogged> {
    return this.userBasicDataObject.asObservable();
  }
}
