import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomandeElencoComponent } from './domande-elenco/domande-elenco.component';
import { DomandeSchedaComponent } from './domande-scheda/domande-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  declarations: [
    DomandeElencoComponent,
    DomandeSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    MatTableModule,
  ]
})
export class DomandeModule { }
